import '../app.css';

import React from 'react';

export function Footer() {
  return (
    <div className="Footer">
      <div className="Footer-container">
        {/* <div className="Footer-item">
          <img width="32px" src="discord_white_logo.svg" />
        </div> */}
        {/* <div
          className="Footer-item"
          onClick={() => {
            window.open("https://www.artblocks.io/project/274", "_blank");
          }}
        >
          <img width="30px" src="artblocks_color_logo.png" />
        </div> */}
        <div
          className="Footer-item"
          onClick={() => {
            window.open("https://twitter.com/Isodream_art", "_blank");
          }}
        >
          <img width="32px" src="twitter_white_logo.svg" />
        </div>
      </div>
    </div>
  );
}
