import '../app.css';

import React from 'react';

export function Header() {
  return (
    <div className="Header">
      <div style={{ position: "relative" }}>
        <div className="Header-container">
          <div
            className="Header-item-title"
            onClick={() => (window.location.href = "/")}
          >
            Isodream
          </div>
        </div>

        <div className="Header-container">
          <div
            className="Header-item-button"
            onClick={() => (window.location.href = "/about")}
          >
            about
          </div>
          <div
            className="Header-item-button"
            onClick={() => (window.location.href = "/teasers")}
          >
            teasers
          </div>
          <div
            className="Header-item-button"
            onClick={() => {
              window.open("https://www.artblocks.io/project/274", "_blank");
            }}
          >
            artblocks
          </div>
          <div
            className="Header-item-button"
            onClick={() => (window.location.href = "/team")}
          >
            team
          </div>
        </div>
      </div>
    </div>
  );
}
