import '../app.css';

import React from 'react';

import { Footer, Header } from '.';

export function AboutPage() {
  return (
    <div className="Layout">
      <Header />
      <div className="Content">
        <h3>What's This?</h3>
        <div className="About">
          Isodream is an on-chain generative art project which aims to explore
          the visual beauty of{" "}
          <a
            href="https://en.wikipedia.org/wiki/Isometric_projection#:~:text=Isometric%20projection%20is%20a%20method,of%20them%20is%20120%20degrees."
            target="_blank"
          >
            isometric projection
          </a>{" "}
          in motion through the use of 3D animation, color, and interactivity.
          Inspired by the unique visual appeal of depthless perspective, each
          Isodream seeks to spark mesmerization and joy through colorful prisms
          which dance about, expanding, collapsing, and orbiting together to
          create beautiful, playful, and thought-provoking outputs. <br />
          <br />
          Isodream will be making its debut as an NFT collection on{" "}
          <a href="https://www.artblocks.io/" target="_blank">
            Art Blocks
          </a>
          , where mints will be available for purchase upon launch. For a sense
          of what an Isodream may look like, check out the preview gifs on the{" "}
          <a href="/teasers">teasers page</a>.
        </div>
        <h3>Project Features</h3>
        <div className="About">
          <ul>
            <li>
              Highly Unique - dozens of random parameters, 20+ unique and
              stunning color palettes
            </li>
            <li>Unbound Animation - animates forever at a steady frame rate</li>
            <li>
              Dimensionless and Responsive - auto-scales to fit any display
              resolution
            </li>
            <li>
              Interactive Controls - supports controls for reorienting and
              pausing animation
            </li>
          </ul>
        </div>
        <h3>A Thank You to Our Minters</h3>
        <div className="About">
          A huge thank you to all of our minters. Isodream is the first official
          NFT launched by <a href="/team">creators henrysdev and AMNDA</a> and
          we feel it's important to recognize collectors and enthusiasts who
          support new and up-and-coming creators in the on-chain generative art
          space. Be sure to keep an eye out for future projects! Particularly if
          you're an Isodream holder :){" "}
          <a href="https://twitter.com/henrysdev" target="_blank">
            @henrysdev
          </a>
        </div>
        {/* <h3>Exclusive for Isodream Holders</h3>
        <div className="About">
          Every Isodream token holder will be granted a white list spot for a
          future NFT project being developed by Isodream developer{" "}
          <a href="https://twitter.com/henrysdev" target="_blank">
            @henrysdev
          </a>{" "}
          . Spots will be one per token holder honored by wallet address for
          those who wish to participate.
          <br /> <br />
          <b>NOTE: </b> Future projects are entirely at the discretion of the
          artist and will be predicated on interest from the community. No
          guarantees are being made about future projects! Please behave
          accordingly.
          <br /> <br />A huge thank you to all of our minters. Isodream is the
          first official NFT launch by its{" "}
          <a href="/team">creators AMNDA and henrysdev</a> and we feel it's
          important to recognize and reward collectors who support new and
          up-and-coming creators in the on-chain generative art space.
        </div> */}
        {/* <h3>Mint Details</h3>
        <div className="About">
          For details on mint price and collection size, refer
        </div> */}
        {/* <h3>Project History</h3>
        <div className="About">
          The inaugural collaboration of software engineer henrysdev and
          designer AMNDA, Isodream is the culmination of the creators combined
          experience in areas ranging from architecture and graphic design to
          game development and 3D graphics programming.
          <br /> <br />
          Inspired by the unique visual appeal of isometric perspective in video
          games like monument valley, both artists set out to create something
          that challenged and emphasized the illusion of depthless perspective.
          The collaborative result of this effort is the interactive,
          dimensionless, and vividly animated Isodream project.
        </div> */}
        <div className="About"></div>
      </div>
      <Footer />
    </div>
  );
}
