import '../app.css';

import React from 'react';

import { Footer, Header } from '.';

export function ExamplesPage() {
  return (
    <div className="Layout">
      <Header />
      <div className="Content" style={{ textAlign: "center" }}>
        <img
          className="Gallery-img"
          src="https://s3.amazonaws.com/isodream.art/nft_gallery_gif_6_compressed.gif"
        />
        <img
          className="Gallery-img"
          src="https://s3.amazonaws.com/isodream.art/nft_gallery_gif_7_compressed.gif"
        />
        <img
          className="Gallery-img"
          src="https://s3.amazonaws.com/isodream.art/nft_gallery_gif_2_compressed.gif"
        />
        <img
          className="Gallery-img"
          src="https://s3.amazonaws.com/isodream.art/nft_gallery_gif_5_compressed.gif"
        />
        <img
          className="Gallery-img"
          src="https://s3.amazonaws.com/isodream.art/nft_gallery_gif_3_compressed.gif"
        />
        <img
          className="Gallery-img"
          src="https://s3.amazonaws.com/isodream.art/nft_gallery_gif_4_compressed.gif"
        />
      </div>
      <Footer />
    </div>
  );
}
