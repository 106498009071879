import '../app.css';

import React from 'react';

import { Footer } from './Footer';
import { Header } from './Header';

export function HomePage() {
  return (
    <div className="Layout">
      <Header />
      <div className="Content-container">
        <div className="Content" style={{ textAlign: "center" }}>
          <div
            className="About"
            style={{ textAlign: "center", paddingBottom: "20px" }}
          >
            A generative exploration into the beauty of isometric projection in
            motion, hosted on the ethereum blockchain
          </div>
          <img
            src="https://s3.amazonaws.com/isodream.art/nft_home_gif_optimized.gif"
            width="70%"
            style={{ maxWidth: "600px", borderRadius: "20px" }}
          />
          <div
            className="About"
            style={{ textAlign: "center", paddingBottom: "20px" }}
          >
            Open for minting on{" "}
            <a href="https://www.artblocks.io/project/274" target="_blank">
              Art Blocks
              <img
                width="30px"
                src="artblocks_logo.png"
                style={{ transform: "translateY(7px)", paddingLeft: "2px" }}
              />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
