import '../app.css';

import React from 'react';

import { Footer } from './Footer';
import { Header } from './Header';

export function ContactPage() {
  return (
    <div className="Layout">
      <Header />

      <div className="Content">
        <h3>henrysdev</h3>
        <div className="Socials-container">
          <div className="Socials-item">
            <a href="https://twitter.com/henrysdev" target="_blank">
              <img width="36px" src="twitter_black_logo.png" />
            </a>
          </div>
          <div>/</div>
          <div className="Socials-item">
            <a href="https://github.com/henrysdev" target="_blank">
              <img width="30px" src="github_black_logo.png" />
            </a>
          </div>
          <div>/</div>
          <div className="Socials-item">
            <a
              href="https://www.artblocks.io/user/0x39545db958636e618eef1655b881da928e1d6f5d"
              target="_blank"
            >
              <img width="30px" src="artblocks_black_logo.png" />
            </a>
          </div>
        </div>
        <div className="About">
          Henry (henrysdev) is an NYC-based software engineer and digital artist
          with over a decade of experience developing video games, websites,
          music, and more.{" "}
          {/* A true digital creative, henrysdev harnesses
          technology as an outlet for crafting unique and compelling experiences
          to connect with others.  */}
          Henry’s passion for software and digital art culminate in his venture
          into generative art, drawing heavily on past experience in game design
          and graphics programming. Stylistically, Henry’s generative art is
          heavily inspired by minimalism, illusion, and contrast; concepts that
          come through boldly in his work.
        </div>
        <h3>AMNDA</h3>
        <div className="Socials-container">
          <div className="Socials-item">
            <a href="https://instagram.com/amanda_heineman_" target="_blank">
              <img width="36px" src="instagram_logo.png" />
            </a>
          </div>
          <div>/</div>
          <div className="Socials-item">
            <a href="https://amanda-heineman.com" target="_blank">
              <img width="30px" src="website_link_logo.png" />
            </a>
          </div>
        </div>
        <div className="About">
          Amanda (AMNDA) is a designer and artist. She received her formal
          design training at the University of Texas School of Architecture, but
          has been experimenting with 2D and 3D art her whole life. Amanda loves
          to play with color, composition and spatial relationships, whether in
          the context of brick and mortar buildings, where site, materials and
          human dynamics inspire and require the designer to constantly adapt
          and iterate, or the virtual space, where the seemingly limitless
          possibilities are only bounded by the imagination of the designer.
          Amanda’s art is characterized by its lively curiosity, utilizing
          vibrant color palettes, structured architectural forms and minimalism
          to create artwork that feels complete, yet is constantly evolving.
        </div>
      </div>
      <Footer />
    </div>
  );
}
