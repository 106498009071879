import './app.css';

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AboutPage, ContactPage, ExamplesPage, HomePage } from './components/';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/teasers" element={<ExamplesPage />} />
        <Route path="/team" element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
